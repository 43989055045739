import * as React from 'react'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'

import { graphql } from 'gatsby'

import { PageWithBgImage, PageContent } from '../components/Page'
import { BasicWhiteText } from '../components/Text'
import LineSeparator from '../components/LineSeparator'
import Col from '../components/Col'
import Title from '../components/Title'
import SEO from '../components/SEO'

import Gap from '../components/Gap'
import ShareWidget from '../components/ShareWidget'
import SuggestionsFooter from '../components/SuggestionsFooter'

// eslint-disable-next-line arrow-body-style
const IndexPage = ({ data }) => {
  // console.log('data index', data)

  return (
    <PageWithBgImage>
      <PageContent>
        <SEO
          title="Forbes speciál: Jak budovat a řídit firmu, která vydrží 100 let"
          titleTemplate="Forbes speciál: Jak budovat a řídit firmu, která vydrží 100 let"
          pathname="/"
        />

        <Col
          alignSelf="center"
          width="100%"
          maxWidth="820px"
          alignItems="center"
        >
          <Gap gap="40px" mobileGap="96px" />

          <Title
            as="h2"
            weight="600"
            size="26px"
            mobileSize="22px"
            lineHeight="150%"
            mobileLineHeight="150%"
            color="white"
            textAlign="center"
          >
            Rodinné firmy pro 22.&nbsp;století
          </Title>

          <Gap gap="16px" mobileGap="8px" />

          <Title.HomepageTitle textAlign="center">
            Jak vybudovat a řídit firmu, která vydrží 100&nbsp;let
          </Title.HomepageTitle>

          <Gap gap="16px" mobileGap="16px" />

          <LineSeparator />

          <Gap gap="24px" mobileGap="24px" />

          <BasicWhiteText textAlign="center" maxWidth="480px">
            Jak ji správně připravit a předat dalším generacím? Inspirujte se
            příběhy a zkušenostmi známých českých rodinných firem i radami
            odborníků.
          </BasicWhiteText>

          <Gap gap="24px" mobileGap="24px" />

          <Col alignSelf="center">
            <ShareWidget
              withoutText
              title="Forbes speciál: Jak budovat a řídit firmu, která vydrží 100 let"
            />
          </Col>

          <Gap gap="40px" mobileGap="40px" />
        </Col>

        <SuggestionsFooter withoutColor hp />
      </PageContent>
    </PageWithBgImage>
  )
}

export default IndexPage

// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#layout
// export const pageQuery = graphql`
//   query {
//     testImg: file(relativePath: { eq: "test.jpg" }) {
//       childImageSharp {
//         gatsbyImageData(
//           width: 1600
//           placeholder: BLURRED
//           formats: [AUTO, WEBP, AVIF]
//         )
//       }
//     }
//   }
// `
